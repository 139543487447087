import { T } from "../../i18n";
import React, { FormEvent, useCallback, useState } from "react";
import { Button, Input } from "nhsuk-react-components";
import { useAPI } from "../../api";
import { ErrorPanel } from "../../components";
import { AuditEventsV2 } from "./AuditEventsV2";
import { CalendarDatePicker } from "../../components/CalendarDatePicker";
import { useSearchParamsState } from "../../hooks";
import { AuditEvents, emptyAuditEvents } from "../../interfaces/IAuditEventV2";
import "./AuditLogV2.scss";
import { DateTime } from "luxon";
import Select, { MultiValue } from "react-select";
import { HttpStatusCode } from "axios";

const eventOptions = [
  {
    value: "User",
    label: T("containers.auditv2.eventType.user") || "",
  },
  {
    value: "UserRole",
    label: T("containers.auditv2.eventType.userRole") || "",
  },
  {
    value: "UserArchiveRecord",
    label: T("containers.auditv2.eventType.userArchiveRecord") || "",
  },
  {
    value: "Person",
    label: T("containers.auditv2.eventType.person") || "",
  },
  {
    value: "Visit",
    label: T("containers.auditv2.eventType.visit") || "",
  },
  {
    value: "Note",
    label: T("containers.auditv2.eventType.note") || "",
  },
  {
    value: "Role",
    label: T("containers.auditv2.eventType.role") || "",
  },
];

export const AuditLogV2 = (): JSX.Element => {
  const [auditEvents, setAuditEvents] = useState<AuditEvents>();
  const [auditLogError, setAuditLogError] = useState<Error>();
  const [fromDate, setFromDate] = useSearchParamsState("from", undefined);
  const [toDate, setToDate] = useSearchParamsState("to", undefined);
  const [eventTypes, setEventTypes] = useSearchParamsState("eventTypes", "");
  const [user, setUser] = useSearchParamsState("user", undefined);
  const [participantId, setParticipantId] = useSearchParamsState(
    "participantId",
    undefined
  );
  const [pageNumber, setPageNumber] = useSearchParamsState("page", undefined);
  const [sortDirection, setSortDirection] = useSearchParamsState(
    "sortDirection",
    "DESC"
  );
  const [loading, setLoading] = useState<boolean>(false);
  const auditAPI = useAPI("auditv2");

  const onSelectEventType = (
    newValues: MultiValue<{ value: string; label: string }>
  ) => {
    if (newValues.length === 0) {
      setEventTypes(undefined);
      return;
    }
    const values = newValues.map(({ value }) => value);
    setEventTypes(values.join(","));
  };

  const onChangeUser = (e: FormEvent<HTMLInputElement>) => {
    const val = e.currentTarget.value.trim();
    setUser(val !== "" ? val : undefined);
  };

  const onChangeParticipantId = (e: FormEvent<HTMLInputElement>) => {
    const val = e.currentTarget.value.trim();
    setParticipantId(val !== "" ? val : undefined);
  };

  const getAuditEvents = useCallback(
    (pageNumber: number, sortDirection: string) => {
      if (
        fromDate &&
        toDate &&
        DateTime.fromISO(fromDate) <= DateTime.fromISO(toDate)
      ) {
        setLoading(true);
        setAuditLogError(undefined);
        const types =
          eventTypes && eventTypes.length > 0 ? eventTypes.split(",") : [];
        auditAPI
          // page numbers in API start from 0, pagination component starts at 1
          .getAuditEvents(
            types,
            fromDate,
            toDate,
            pageNumber - 1,
            10,
            sortDirection,
            user,
            participantId
          )
          .then((events) => {
            setAuditEvents(events);
            setTimeout(() => setLoading(false), 600);
          })
          .catch((error) => {
            setLoading(false);
            const status = error.response?.status;
            if (status === HttpStatusCode.NotFound) {
              setAuditEvents(emptyAuditEvents);
            } else setAuditLogError(error);
          });
      } else {
        setAuditEvents(undefined);
        setAuditLogError(new Error("Please select a valid date range"));
      }
    },
    [auditAPI, eventTypes, fromDate, toDate, user, participantId]
  );

  const handlePageChange = (pageNumber: number) => {
    setPageNumber(String(pageNumber));
    if (sortDirection) getAuditEvents(pageNumber, sortDirection);
  };

  const handleSortChange = () => {
    const newSortDirection = sortDirection === "ASC" ? "DESC" : "ASC";
    setSortDirection(newSortDirection);
    if (pageNumber) getAuditEvents(+pageNumber, newSortDirection);
  };

  return (
    <main className="nhsuk-main-wrapper">
      {auditLogError && (
        <ErrorPanel title="There is an error" label="query-error">
          {auditLogError.message}
        </ErrorPanel>
      )}
      <h1>{T("containers.auditv2.title")}</h1>
      <h3>{T("containers.auditv2.byDetail")}</h3>
      <div className="nhsuk-form-group detail-form-group">
        <div>
          <label htmlFor="eventTypes" className="nhsuk-label">
            {T("containers.auditv2.eventTypeLabel")}
          </label>
          <Select
            isMulti
            name="eventTypes"
            inputId="eventTypes"
            options={eventOptions}
            className="event-type-select"
            classNamePrefix="event-type-select"
            onChange={onSelectEventType}
            defaultValue={
              eventTypes && eventTypes !== ""
                ? eventTypes
                    .split(",")
                    .map((value) => ({ value, label: value }))
                : undefined
            }
          />
        </div>
        <div className="participant-id">
          <label htmlFor="participantId" className="nhsuk-label">
            {T("containers.auditv2.participantIdLabel")}
          </label>
          <Input
            id="participantId"
            width="20"
            value={participantId}
            onChange={onChangeParticipantId}
          />
        </div>
        <div className="user-email">
          <label htmlFor="userEmail" className="nhsuk-label">
            {T("containers.auditv2.userEmailLabel")}
          </label>
          <Input
            id="userEmail"
            width="20"
            value={user}
            onChange={onChangeUser}
          />
        </div>
      </div>
      <h3>{T("containers.auditv2.byDate")}</h3>
      <div className="date-pickers nhsuk-form-group">
        <label htmlFor="dateFrom" className={"nhsuk-label"}>
          {T("containers.auditv2.datePickerFromLabel")}
        </label>
        <label htmlFor="dateTo" className={"nhsuk-label"}>
          {T("containers.auditv2.datePickerToLabel")}
        </label>
        <CalendarDatePicker
          value={fromDate ? fromDate : "YYYY-MM-DD"}
          onChange={(e: any) => setFromDate(e.detail.value)}
          identifier={"dateFrom"}
          data-testid={"datePickerFrom"}
        />
        <CalendarDatePicker
          value={toDate ? toDate : "YYYY-MM-DD"}
          onChange={(e: any) => setToDate(e.detail.value)}
          identifier={"dateTo"}
          data-testid={"datePickerTo"}
        />
      </div>
      <Button
        onClick={() => {
          handlePageChange(1);
        }}
      >
        {T("containers.auditv2.action")}
      </Button>
      {auditEvents && pageNumber && sortDirection && (
        <AuditEventsV2
          loading={loading}
          auditEvents={auditEvents}
          currentPage={+pageNumber}
          sortDirection={sortDirection}
          changeSortFn={handleSortChange}
          changePageFn={handlePageChange}
        ></AuditEventsV2>
      )}
    </main>
  );
};
